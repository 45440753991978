import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";

import Header from "./Components/Header/Header";
import PaginationControls from "./Components/PaginationControls/PaginationControls";
import PhotoMessageInput from "./Components/PhotoMessageInput/PhotoMessageInput";
import {
  API_UPLOAD_URL,
  API_LIST_URL,
  API_EVENT_DETAILS_URL,
  API_SAFE_SEARCH_URL,
  API_DELETE_PHOTO,
  API_HEART,
} from "./endpoints";
import { handleFileChange } from "./utils/fileUtils"; // Import the function
import useIsMobile from "./utils/screenSizeDetection";

import Footer from "./Components/Footer/Footer";
import CoverImage from "./Components/CoverImage/CoverImage";
import ScrollToTopButton from "./Components/ScrollToTopButton/ScrollToTopButton";

import TermsAndConditionsModal from "./Components/TermsAndConditionsModal/TermsAndConditionsModal"; // Import the new modal component
const specialUserId = "admin"; // Replace with the actual user ID

function Album() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get("eventId");

  const [file, setFile] = useState(null);
  const [uploaderName, setUploaderName] = useState("");
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [albumOf, setAlbumOf] = useState("");
  const [isLoadingEventDetails, setIsLoadingEventDetails] = useState(true); // Loading state for event details
  const [photoMessage, setPhotoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [clickedPhoto, setClickedPhoto] = useState(null); // State to track clicked photo
  const [sortOrder, setSortOrder] = useState("desc"); // Default is 'desc' for newest first
  const [isSlideshowActive, setIsSlideshowActive] = useState(false); // To manage slideshow state
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Track current slide
  const [currentPage, setCurrentPage] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const isMobile = useIsMobile(); // Determine if the user is on mobile or desktop
  const photosPerPage = isMobile ? 16 : 15; // Set photos per page based on screen size
  const [isBannerVisible, setIsBannerVisible] = useState(true); // State to control banner visibility

  const coverImageUrlPortada = { coverImageUrl }; // Replace this with your fetch logic
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  //14-10
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);
  const dropdownRef = useRef(null); // Ref for the dropdown menu


  const handleDropdownClick = (photoId) => {
    console.log(`Dropdown clicked. Current ID: ${visibleDropdownId}, Clicked ID: ${photoId}`);
    setVisibleDropdownId(prevId => {
      const newId = (prevId === photoId ? null : photoId);
      console.log(`Setting new ID: ${newId}`); // Log the new state
      return newId;
    });
  };
  

 // Close the dropdown when clicking outside
 useEffect(() => {
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisibleDropdownId(null); // Close the dropdown
    }
  };

  // Use 'click' event instead of 'mousedown'
  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);

  
  // Function to open the photo in full-screen mode
  const openPhotoInFullScreen = () => {
    setIsFullScreen(true);
    document.body.style.overflow = "hidden"; // Prevent body scrolling
  };

  const [showPhotographerPhotos, setShowPhotographerPhotos] = useState(false); // Toggle state
  // Calculate total pages for photographer photos
  const calculateTotalPhotographerPages = () => {
    return Math.ceil(
      photos.filter((photo) => photo.isPhotographer).length / photosPerPage
    );
  };

  // Calculate total pages for non-photographer photos
  const calculateTotalNonPhotographerPages = () => {
    return Math.ceil(
      photos.filter((photo) => !photo.isPhotographer).length / photosPerPage
    );
  };

  // Determine the total pages based on the current view
  const totalPages = showPhotographerPhotos
    ? calculateTotalPhotographerPages()
    : calculateTotalNonPhotographerPages();

  // Effect to reset current page when toggling views
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1); // Reset to page 1 if the current page exceeds total pages
    }
  }, [showPhotographerPhotos, totalPages]); // Run this effect when view or total pages change

  // Change page function
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // // Toggle between photographer and non-photographer photos
  const handleToggleChange = () => {
    setShowPhotographerPhotos((prevState) => !prevState);
  };

  // This will filter the photos based on the condition
  const filterPhotos = () => {
    const newFilteredPhotos = photos.filter((photo) => {
      return showPhotographerPhotos
        ? photo.isPhotographer
        : !photo.isPhotographer;
    });

    // Set the filtered photos into state
    setFilteredPhotos(newFilteredPhotos);
  }; // Determine if there are any photographer photos

  const hasPhotographerPhotos = photos.some(
    (photo) => photo.isPhotographer === true
  );

  // Function to close full-screen mode
  const closePhotoInFullScreen = () => {
    setIsFullScreen(false);
    document.body.style.overflow = "auto"; // Restore body scrolling
    closeOverlay(); // Call the provided close function
  };
  // const paginationRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const [startX, setStartX] = useState(0); // Store the starting touch position

  // Handle the start of a touch event
  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartX(touch.clientX); // Set the starting position
  };

  // Handle the end of a touch event
  const handleTouchEnd = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX; // Get the ending position
    handleSwipe(startX, endX); // Call the swipe handler
  };

  // Logic to determine the swipe direction
  const handleSwipe = (startX, endX) => {
    const distance = startX - endX;
    if (Math.abs(distance) > 50) {
      // Swipe threshold
      if (distance > 0) {
        nextPhoto(); // Swiped left
      } else {
        prevPhoto(); // Swiped right
      }
    }
  };

  // Check local storage to see if the banner should be hidden
  useEffect(() => {
    const bannerClosed = localStorage.getItem("bannerClosed");
    if (bannerClosed) {
      setIsBannerVisible(false);
    }
  }, []);

  const openModal = () => setIsModalOpen(true); // Open modal function
  const closeModal = () => setIsModalOpen(false); // Close modal function

  useEffect(() => {
    const existingUserId = localStorage.getItem("userId");
    if (!existingUserId) {
      const newUserId = `user_${Date.now()}_${Math.random()
        .toString(36)
        .substr(2, 9)}`;
      localStorage.setItem("userId", newUserId);
    }
  }, []);

  // Determine which photos to display based on the current page
  const displayedPhotos = (
    showPhotographerPhotos
      ? photos.filter((photo) => photo.isPhotographer)
      : photos.filter((photo) => !photo.isPhotographer)
  ).slice((currentPage - 1) * photosPerPage, currentPage * photosPerPage);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!eventId) {
      navigate("/");
    }
  }, [eventId, navigate]);

  const downloadPhoto = async (photoUrl, photoId) => {
    try {
      const response = await axios.get(photoUrl, {
        responseType: "blob", // This is key to handle binary data
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `photo_${photoId}`); // Set the download attribute
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Close the dropdown after the download action
      setVisibleDropdownId(null);
    } catch (error) {
      console.error("Error downloading the photo:", error);
    }
  };

  // Fetch event details
  const fetchEventDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_EVENT_DETAILS_URL}?eventId=${eventId}`
      );
      const { coverImageUrl, customMessage, albumOf } = response.data;

      setCoverImageUrl(coverImageUrl);
      setCustomMessage(customMessage);
      setAlbumOf(albumOf);
      setIsLoadingEventDetails(false); // Set loading to false once data is fetched
    } catch (error) {
      setIsLoadingEventDetails(false);
      if (error.response && error.response.status === 404) {
        // Album not found, redirect to home and show alert
        navigate("/album/album-not-found");
      } else {
        console.error("Error fetching event details:", error);
      }
    }
  }, [eventId, navigate]);

  const handleLike = async (photoId) => {
    setPhotos((prevPhotos) =>
      prevPhotos.map((photo) => {
        if (photo.photoId === photoId) {
          const updatedLikes =
            typeof photo.likes === "number" ? photo.likes + 1 : 1;
          // If likes reach 20, stop incrementing and show 20+
          return {
            ...photo,
            likes: updatedLikes >= 20 ? 20 : updatedLikes, // Stop at 20
            isLikedAbove20: updatedLikes >= 20 ? true : false, // Track if it's above 20
          };
        }
        return photo;
      })
    );

    const heartContainer = document.getElementById(
      `heart-animation-${photoId}`
    );
    if (heartContainer) {
      const heart1 = document.createElement("div");
      heart1.classList.add("floating-heart");
      heart1.innerHTML = "❤";
      const heart2 = document.createElement("div");
      heart2.classList.add("floating-heart");
      heart2.innerHTML = "❤";
      heartContainer.appendChild(heart1);
      setTimeout(() => heartContainer.removeChild(heart1), 1000);
      setTimeout(() => {
        heartContainer.appendChild(heart2);
        setTimeout(() => heartContainer.removeChild(heart2), 1000);
      }, 300);
    }

    try {
      await axios.post(`${API_HEART}`, { photoId });
    } catch (error) {
      console.error("Error liking photo:", error);
      // Revert the optimistic update if API fails
      setPhotos((prevPhotos) =>
        prevPhotos.map((photo) =>
          photo.photoId === photoId
            ? {
                ...photo,
                likes:
                  typeof photo.likes === "number"
                    ? Math.max(photo.likes - 1, 0)
                    : 0, // Revert decrement
                isLikedAbove20: photo.likes - 1 >= 20 ? true : false, // Revert isLikedAbove20
              }
            : photo
        )
      );
    }
  };

  const fetchPhotos = useCallback(async () => {
    try {
      const response = await axios.get(`${API_LIST_URL}`, {
        params: {
          eventId,
          order: "desc", // Pass the sortOrder parameter
        },
      });

      // Initialize likes if not present
      const updatedPhotos = response.data.map((photo) => ({
        ...photo,
        likes: photo.likes || 0, // Ensure that each photo has a likes field
      }));
      // setPhotos(response.data);

      setPhotos(updatedPhotos);
    } catch (error) {
      console.error("Error fetching photos:", error);
    }
  }, [eventId]); // Dependency on eventId

  // Initial fetch for photos and event details
  useEffect(() => {
    if (eventId) {
      fetchPhotos(); // Fetch photos
      fetchEventDetails(); // Fetch event details
    }
  }, [eventId, fetchPhotos, fetchEventDetails]);

  const handleUpload = async () => {
    if (loading) return; // Prevent multiple uploads
    const userId = localStorage.getItem("userId");
    console.log("User ID:", userId);
    if (!file || file.length === 0) {
      alert("Por favor, seleccioná al menos un archivo para subir.");
      return;
    }
    if (!uploaderName.trim()) {
      alert("Por favor, ingresá tu nombre antes de subir la foto.");
      return;
    }

    setLoading(true);

    try {
      for (const selectedFile of file) {
        const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
        const uniqueFileName = `${timestamp}_${selectedFile.name}`;

        // Step 1: Get the upload URL from the getUploadUrl function
        const response = await axios.post(API_UPLOAD_URL, {
          fileName: uniqueFileName,
          fileType: selectedFile.type,
          uploaderName: uploaderName || "Anónimo",
          photoMessage: photoMessage || "",
          eventId,
          userId,
        });
        console.log("Upload Response:", response.data);

        const { uploadUrl } = response.data;

        // Step 2: Upload the file to Cloud Storage
        await axios.put(uploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
        });
        // console.log(`File ${uniqueFileName} uploaded successfully.`);

        // Step 3: SafeSearch detection after uploading

        try {
          const safeSearchResponse = await axios.post(API_SAFE_SEARCH_URL, {
            fileName: uniqueFileName,
            eventId,
            uploaderName: uploaderName || "Anónimo",
            photoMessage: photoMessage || "",
            userId,
          });
          // console.log(`'SafeSearch request:' ${userId}`);

          const { error, success } = safeSearchResponse.data;
          if (error) {
            // Handle SafeSearch error (e.g., notify user that the image was flagged)
            alert(
              `La foto ${selectedFile.name} está en proceso de evaluación debido a posibles contenidos inapropiados.`
            );
            continue; // Skip to the next file if flagged
          }
        } catch (err) {
          // Handle errors, including 400 responses and network issues
          if (err.response && err.response.status === 400) {
            alert(
              `La foto ${selectedFile.name} está en proceso de evaluación debido a posibles contenidos inapropiados.`
            );
          } else {
            console.error("Error during SafeSearch detection:", err);
            // alert(`La foto ${selectedFile.name} contiene contenido inapropiado y no fue subida.`);
          }
          continue; // Skip to the next file on error
        }
      }

      // If successful, update the list of photos
      // Assuming userId is already defined earlier in the function
      const photosResponse = await axios.get(
        `${API_LIST_URL}?eventId=${eventId}`
      );
      setPhotos(photosResponse.data);
      setFile(null);
      setUploaderName("");
      setPhotoMessage("");

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error subiendo el archivo:", error);
    } finally {
      setLoading(false);
    }
  };

  const deletePhoto = async (
    photoId,
    photoUploaderId,
    eventId,
    checkSpecialId = false
  ) => {
    const currentUserId = localStorage.getItem("userId");

    // Check if the current user is the uploader
    if (currentUserId === photoUploaderId) {
      // If the user is the uploader, delete the photo immediately
      if (window.confirm("Estás seguro que querés borrar la foto?")) {
        await performDelete(photoId, eventId);
      }
      // After deletion, update the photos state
      setPhotos((prevPhotos) => {
        const updatedPhotos = prevPhotos.filter(
          (photo) => photo.id !== photoId
        );

        // Check if we're currently showing photographer photos and if any remain
        if (
          showPhotographerPhotos &&
          !updatedPhotos.some((photo) => photo.isPhotographer === true)
        ) {
          setShowPhotographerPhotos(false); // Switch to non-photographer photos
        }

        return updatedPhotos;
      });

      // Close the dropdown after the deletion
      setVisibleDropdownId(null);
    } else if (checkSpecialId) {
      // If the user is not the uploader, prompt for the special identifier
      const userIdInput = prompt(
        "Identificamos que vos no subiste esta foto. Para borrarla, ingresá el PIN secreto:"
      );

      if (userIdInput === "admin") {
        if (window.confirm("Estás seguro que queres borrar la foto?")) {
          await performDelete(photoId, eventId);
        }
        // After deletion, update the photos state
        setPhotos((prevPhotos) => {
          const updatedPhotos = prevPhotos.filter(
            (photo) => photo.id !== photoId
          );

          // Check if we're currently showing photographer photos and if any remain
          if (
            showPhotographerPhotos &&
            !updatedPhotos.some((photo) => photo.isPhotographer === true)
          ) {
            setShowPhotographerPhotos(false); // Switch to non-photographer photos
          }

          return updatedPhotos;
        });
      } else {
        alert(
          "Identificador inválido. No tenés permiso para borrar esta foto."
        );
      }
    }
  };

  // Helper function to perform the actual deletion
  const performDelete = async (photoId, eventId) => {
    try {
      // Send request to delete the photo from both Cloud Storage and Firestore
      await axios.post(`${API_DELETE_PHOTO}`, { photoId, eventId });

      // Remove the photo from the frontend after successful deletion
      setPhotos((prevPhotos) =>
        prevPhotos.filter((photo) => photo.photoId !== photoId)
      );
      alert("Foto borrada con éxito.");
    } catch (error) {
      console.error("Error deleting photo:", error);
      alert("Falla al borrar la foto.");
    }
  };

  const startSlideshow = () => {
    // Filter photos based on the current view mode (guest or photographer)
    const filteredPhotos = photos.filter((photo) =>
      showPhotographerPhotos ? photo.isPhotographer : !photo.isPhotographer
    );

    if (filteredPhotos.length === 0) {
      alert("No hay fotos para mostrar.");
      return;
    }

    setFilteredPhotos(filteredPhotos);
    setIsSlideshowActive(true);
    setCurrentSlideIndex(0);
    setIsPaused(false);
  };

  const stopSlideshow = () => {
    setIsSlideshowActive(false);
    setIsPaused(false); // Reset pause state when stopped
  };

  // Pause slideshow
  const pauseSlideshow = () => {
    setIsPaused(true);
  };

  // Resume slideshow
  const resumeSlideshow = () => {
    setIsPaused(false);
  };

  // Move to the next slide
  const nextSlide = useCallback(() => {
    setCurrentSlideIndex((prevIndex) => {
      // Get the current filtered photo count
      const totalFilteredPhotos = filteredPhotos.length;
      return (prevIndex + 1) % totalFilteredPhotos; // Loop around the filtered photos
    });
  }, [filteredPhotos]);

  // Move to the previous slide

  const prevSlide = () => {
    setCurrentSlideIndex((prevIndex) => {
      const totalFilteredPhotos = filteredPhotos.length;
      return prevIndex === 0 ? totalFilteredPhotos - 1 : prevIndex - 1;
    });
  };

  // Autoplay functionality with useEffect
  useEffect(() => {
    let slideshowInterval;

    if (isSlideshowActive && !isPaused) {
      // Start the interval to move to the next slide every 3 seconds
      slideshowInterval = setInterval(() => {
        nextSlide();
      }, 3000);
    }

    // Cleanup the interval when slideshow stops, pauses, or component unmounts
    return () => {
      if (slideshowInterval) {
        clearInterval(slideshowInterval);
      }
    };
  }, [isSlideshowActive, isPaused, nextSlide]); // Only include isSlideshowActive, isPaused, and nextSlide as dependencies

  // Function to open a photo in fullscreen
  // const handlePhotoClick = (photo) => {
  //   const photoIndex = photos.findIndex((p) => p.photoId === photo.photoId);
  //   setCurrentSlideIndex(photoIndex);
  //   setClickedPhoto(photo); // Sets the photo in fullscreen mode
  // };

  // Click handler for photo to open in full-screen mode
  const handlePhotoClick = (photo) => {
    const filteredPhotos = photos.filter((photo) =>
      showPhotographerPhotos ? photo.isPhotographer : !photo.isPhotographer
    );

    // Find the index of the clicked photo in the filtered array
    const photoIndex = filteredPhotos.findIndex(
      (p) => p.photoId === photo.photoId
    );
    setCurrentSlideIndex(photoIndex); // Set the current index to the clicked photo's index in the filtered photos
    setClickedPhoto(photo); // Set the clicked photo for full-screen mode
  };

  const closeOverlay = () => {
    setClickedPhoto(null); // Close the overlay
  };

  // Function to go to the next photo
  // const nextPhoto = () => {
  //   if (currentSlideIndex < photos.length - 1) {
  //     setCurrentSlideIndex((prevIndex) => prevIndex + 1);
  //     setClickedPhoto(photos[currentSlideIndex + 1]); // Updates the clickedPhoto state
  //   }
  // };
  const nextPhoto = () => {
    const filteredPhotos = photos.filter((photo) =>
      showPhotographerPhotos ? photo.isPhotographer : !photo.isPhotographer
    );

    // Check if there is a next photo within the filtered array
    if (currentSlideIndex < filteredPhotos.length - 1) {
      setCurrentSlideIndex((prevIndex) => prevIndex + 1);
      setClickedPhoto(filteredPhotos[currentSlideIndex + 1]); // Update clicked photo to the next one in filtered photos
    }
  };

  // Function to go to the previous photo
  // const prevPhoto = () => {
  //   if (currentSlideIndex > 0) {
  //     setCurrentSlideIndex((prevIndex) => prevIndex - 1);
  //     setClickedPhoto(photos[currentSlideIndex - 1]); // Updates the clickedPhoto state
  //   }
  // };
  // Function to handle "previous" swipe
  const prevPhoto = () => {
    const filteredPhotos = photos.filter((photo) =>
      showPhotographerPhotos ? photo.isPhotographer : !photo.isPhotographer
    );

    // Check if there is a previous photo within the filtered array
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex((prevIndex) => prevIndex - 1);
      setClickedPhoto(filteredPhotos[currentSlideIndex - 1]); // Update clicked photo to the previous one in filtered photos
    }
  };

  if (isLoadingEventDetails) {
    return (
      <div className="loading-container">
        <div className="loader-spinner"></div>
        <p className="loading-text">Cargando...</p>
      </div>
    );
  }

  //textarea
  const handlePhotoMessageChange = (e) => {
    const inputText = e.target.value;
    const wordCount = inputText.trim().split(/\s+/).length;

    if (wordCount > 8) {
      setErrorMessage("El mensaje no debe tener más de 8 palabras.");
    } else {
      setErrorMessage(""); // Clear error message if valid
      setPhotoMessage(inputText);
    }
  };

  const albumUrl = `${window.location.origin}/album?eventId=${eventId}`;

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
    localStorage.setItem("bannerClosed", "true"); // Set flag in local storage
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(albumUrl);
    alert("¡Enlace copiado!"); // Optional: notify the user
  };

  return (
    <>
      <div className="App">
        <Header />
        {/* Add Helmet to dynamically set the Open Graph meta tags */}

        <Helmet>
          {/* <title>{`Album para: ${albumOf}`}</title> */}
          <title>Álbum para Eventos con Código QR</title>
          <meta
            property="og:image"
            content={`${coverImageUrl}?v=${new Date().getTime()}`}
          />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="314" />
        </Helmet>

        <div className="event-details">
          <CoverImage coverImageUrl={coverImageUrl} /> {/* Reminder Banner */}
          {isBannerVisible && (
            <div className="reminder-banner">
              <p>
                Para acceder fácilmente al álbum más tarde sin el QR,{" "}
                <strong>copia el enlace de abajo</strong> y guardalo entre tus
                notas o compartilo con tu grupo de WhatsApp.
              </p>

              <button onClick={handleCopyLink} className="copy-link-button">
                Copiar Enlace
              </button>
              <i
                onClick={handleCloseBanner}
                className="fas fa-times close-banner-button" // Font Awesome X icon
                aria-hidden="true" // Accessibility attribute
              ></i>
            </div>
          )}
        </div>

        <div>
          <div>
            <div>{/* Render your album content here */}</div>

            {/* Render your album content here */}
          </div>
          <h1>{albumOf}</h1>
          <input
            type="text"
            placeholder="Tu nombre (requerido)"
            value={uploaderName}
            onChange={(e) => setUploaderName(e.target.value)}
          />

          <div>
            <PhotoMessageInput
              value={photoMessage}
              onChange={handlePhotoMessageChange}
              errorMessage={errorMessage}
            />
          </div>

          {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}

          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(event) => handleFileChange(event, setFile)} // Use the utility function
            ref={fileInputRef}
          />
          <div className="upload-container">
            <button
              className="upload-text"
              onClick={handleUpload}
              style={{ whiteSpace: "normal", textAlign: "center" }}
              disabled={loading || errorMessage !== ""}
            >
              Hacé clic para subir tu foto{" "}
            </button>
            <button
              className="icon-button"
              onClick={() => {
                setFile(null); // Clear the file state
                if (fileInputRef.current) {
                  fileInputRef.current.value = ""; // Clear the input field
                }
              }}
              disabled={!file} // Disable if no file is selected
            >
              <i className="fas fa-trash"></i> {/* Font Awesome trash icon */}
            </button>
            <button
              className="icon-button-reload"
              onClick={fetchPhotos}
              disabled={!photos.length} // Disable if there are no photos
            >
              <i className="fas fa-sync-alt reload-icon"></i>
            </button>
          </div>
        </div>

        {loading && (
          <div className="loading-backdrop">
            <div className="loading-box">
              <div className="loader"></div>
              <p>Tu foto se está subiendo...</p>
            </div>
          </div>
        )}

        <h2>¡Así la Pasamos!</h2>

        {/* Toggle to switch between photographer and non-photographer photos */}
        <div className="icon-buttons">
          <button
            className={`icon-button ${!showPhotographerPhotos ? "active" : ""}`}
            // onClick={() => setShowPhotographerPhotos(false)}
            onClick={() => {
              setShowPhotographerPhotos(false);
              setCurrentPage(1); // Always reset to page 1 when switching to non-photographer photos
            }}
            disabled={showPhotographerPhotos && !hasPhotographerPhotos} // Disable if switching to non-photographer and there are no photographer photos
          >
            <i className="fas fa-users"></i> {/* Icon for users */}
          </button>
          <button
            className={`icon-button ${showPhotographerPhotos ? "active" : ""}`}
            // onClick={() => setShowPhotographerPhotos(true)}
            onClick={() => {
              setShowPhotographerPhotos(true);
              setCurrentPage(1); // Always reset to page 1 when switching to photographer photos
            }}
            disabled={!showPhotographerPhotos && !hasPhotographerPhotos} // Disable if switching to photographer and there are no photographer photos
          >
            <i className="fas fa-camera-retro"></i>{" "}
            {/* Icon for photographers */}
          </button>
        </div>

        {/* Content that makes the page scroll */}
        <div>
          {/* Long content */}

          <div className="gallery">
            {photos
              .filter((photo) => {
                // Filter photos based on whether the toggle is on for photographer photos or not
                if (showPhotographerPhotos) {
                  return photo.isPhotographer; // Show only photographer photos
                } else {
                  return !photo.isPhotographer; // Show only user photos
                }
              })
              .slice(
                (currentPage - 1) * photosPerPage,
                currentPage * photosPerPage
              )

              .map((photo) => (
                <div key={photo.photoId} className="photo-container">
                  <img
                    src={photo.photoUrl}
                    alt="Uploaded"
                    style={{ width: "200px", position: "relative" }}
                    className="gallery-photo"
                    onClick={() => handlePhotoClick(photo)} // Pass the entire photo object
                    onTouchStart={handleTouchStart} // Add touch start event
                    onTouchEnd={handleTouchEnd} // Add touch end event
                  />

                  <p>Subido por: {photo.uploaderName}</p>
                  {photo.photoMessage && (
                    <p className="photo-message-text">{photo.photoMessage}</p>
                  )}

                  <i
                    className="fas fa-ellipsis-h action-icon"
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent click event from bubbling up
                      handleDropdownClick(photo.photoId); // Call the toggle function
                    }}                    />
                  {visibleDropdownId === photo.photoId && ( // Only show dropdown for the clicked photo
                    <div
                      ref={dropdownRef} // Attach the ref here
                     className={`photo-dropdown-menu ${visibleDropdownId === photo.photoId ? "show" : ""}`} // Ensure 'show' class is always added if visible

                    >
                      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                        <li
                          className="photo-dropdown-item"
                          onClick={() =>
                            downloadPhoto(photo.photoUrl, photo.photoId)
                          }
                        >
                          Descargar
                        </li>
                        <li
                          className="photo-dropdown-item"
                          onClick={() => {
                            const currentUserId =
                              localStorage.getItem("userId");
                            // Check if the current user is the uploader
                            if (currentUserId === photo.userId) {
                              deletePhoto(photo.photoId, photo.userId, eventId); // Allow deletion for uploader
                            } else {
                              // For non-uploaders, ask for special identifier inside deletePhoto
                              deletePhoto(
                                photo.photoId,
                                photo.userId,
                                eventId,
                                true
                              ); // Pass true to indicate special identifier check
                            }
                          }}
                        >
                          Excluir
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* <i
                    className="fas fa-download download-icon"
                    onClick={() => downloadPhoto(photo.photoUrl, photo.photoId)}
                  /> */}

                  {/* Display the like button */}
                  <div className="like-container">
                    {/* {console.log(photo.likes)} */}
                    <span className="heart-icon-container">
                      <i
                        className="fas fa-heart heart-icon"
                        data-likes={
                          (typeof photo.likes === "number" &&
                            photo.likes > 20) ||
                          photo.isLikedAbove20
                            ? "20+"
                            : photo.likes === 0 ||
                              typeof photo.likes !== "number"
                            ? "me gusta"
                            : photo.likes
                        }
                        // data-likes={photo.likes} // Use data attribute to store like count
                        onClick={() => handleLike(photo.photoId)}
                      ></i>
                    </span>
                    <div
                      id={`heart-animation-${photo.photoId}`}
                      className="heart-animation-container"
                    ></div>
                  </div>

                  {/* <i
                    className="fas fa-trash-alt trash-icon"
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      const currentUserId = localStorage.getItem("userId");

                      // Check if the current user is the uploader
                      if (currentUserId === photo.userId) {
                        deletePhoto(photo.photoId, photo.userId, eventId); // Allow deletion for uploader
                      } else {
                        // For non-uploaders, ask for special identifier inside deletePhoto
                        deletePhoto(photo.photoId, photo.userId, eventId, true); // Pass true to indicate special identifier check
                      }
                    }}
                  /> */}
                </div>
              ))}
          </div>
        </div>

        {/* Photo Overlay */}
        {clickedPhoto && (
          // <div className="photo-overlay">
          <div className={`photo-overlay ${isFullScreen ? "show" : "hide"}`}>
            <div className="overlay-close" onClick={closePhotoInFullScreen}>
              {/* <div className="overlay-close" onClick={closeOverlay}> */}
              <i className="fas fa-times"></i>
            </div>

            {/* Left arrow for previous photo */}
            <button
              className="slideshow-control prev"
              onClick={prevPhoto}
              disabled={currentSlideIndex === 0}
            >
              <i className="fas fa-chevron-left"></i>
            </button>

            <img
              src={clickedPhoto.photoUrl}
              alt="Clicked"
              className="overlay-photo"
              onLoad={openPhotoInFullScreen} // Open full-screen after loading
              onTouchStart={handleTouchStart} // Add touch start event
              onTouchEnd={handleTouchEnd} // Add touch end event
            />

            {/* Right arrow for next photo */}
            <button
              className="slideshow-control next"
              onClick={nextPhoto}
              disabled={currentSlideIndex === photos.length - 1}
            >
              <i className="fas fa-chevron-right"></i>
            </button>

            {clickedPhoto.uploaderName && (
              <div className="overlay-uploader-name">
                <p>
                  {clickedPhoto.uploaderName}
                  {clickedPhoto.photoMessage
                    ? ` - ${clickedPhoto.photoMessage}`
                    : ""}
                </p>
              </div>
            )}
          </div>
        )}

        {/* Slideshow Button */}
        <div className="slideshow-container">
          <button
            className={`slideshow-toggle ${isSlideshowActive ? "active" : ""}`}
            onClick={isSlideshowActive ? stopSlideshow : startSlideshow}
          >
            {isSlideshowActive
              ? "Cerrar presentación"
              : "Ver todas las fotos (autoshow)"}
          </button>
          <button
            className="icon-button-reload"
            onClick={fetchPhotos}
            disabled={!photos.length} // Disable if there are no photos
          >
            <i className="fas fa-sync-alt reload-icon"></i>
          </button>
        </div>

        {/* Slideshow Modal */}
        {isSlideshowActive && (
          <div
            className={`slideshow-modal ${isSlideshowActive ? "active" : ""}`}
          >
            <button className="slideshow-control prev" onClick={prevSlide}>
              {/* &lt; */}
              <i className="fas fa-chevron-left"></i>
            </button>
            <img
              className="slideshow-image"
              // src={photos[currentSlideIndex]?.photoUrl}

              src={
                filteredPhotos[currentSlideIndex]?.photoUrl // Use filteredPhotos for the image source
              }
              alt="Slideshow"
            />

            <button className="slideshow-control next" onClick={nextSlide}>
              <i className="fas fa-chevron-right"></i>
            </button>

            <button
              className="pause-slideshow"
              onClick={isPaused ? resumeSlideshow : pauseSlideshow}
            >
              <i className={isPaused ? "fas fa-play" : "fas fa-pause"}></i>
            </button>

            <button className="close-slideshow" onClick={stopSlideshow}>
              <i className="fas fa-times"></i>
            </button>

            {filteredPhotos[currentSlideIndex] && ( // Change from photos to filteredPhotos
              <div className="slideshow-text-overlay">
                <p>
                  {filteredPhotos[currentSlideIndex].uploaderName}{" "}
                  {/* Use filteredPhotos for uploader name */}
                  {filteredPhotos[currentSlideIndex].photoMessage
                    ? ` - ${filteredPhotos[currentSlideIndex].photoMessage}`
                    : ""}
                </p>
              </div>
            )}
          </div>
        )}

        {/* Scroll to Top Button */}

        {!isSlideshowActive && (
          <ScrollToTopButton hasPhotos={photos.length > 0} />
        )}
        {/* Pagination Controls */}
        {/* <div className="pagination-controls">
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          // ref={paginationRef}
        />
   </div> */}
        <div className="pagination-controls">
          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>

        {/* Render photos based on the current view */}
        <div className="photos">
          {displayedPhotos.map((photo) => (
            <div key={photo.id}>{/* Render your photo component here */}</div>
          ))}
        </div>

        <Footer />

        {/* Modal component */}
      </div>
      {/* This is the link outside your app container to open the modal */}
      <button
        type="button"
        className="modal-link"
        onClick={openModal}
        style={{ background: "none" }}
      >
        Términos y Condiciones
      </button>

      {/* Modal component */}
      <TermsAndConditionsModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}

export default Album;

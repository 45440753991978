import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "./Components/Header/Header";
import axios from "axios";
import "./App.css";
import "./assets/styles/CreateEvent.css";
import { QRCodeCanvas } from "qrcode.react";

import { API_CREATE_EVENT_URL, API_GET_EVENT_LIST } from "./endpoints";

const SECRET_KEY = "Catalina83"; // Define your fixed secret key

const CreateEvent = () => {
  const [eventId, setEventId] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [coverImageFile, setCoverImageFile] = useState(null); // For file upload
  const [customMessage, setCustomMessage] = useState("");
  const [albumOf, setAlbumOf] = useState("");
  const [albumCreatedBy, setAlbumCreatedBy] = useState(""); // New state for Albumcreatedby

  const [secret, setSecret] = useState(""); // State for secret input
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [eventUrl, setEventUrl] = useState(""); // State to store the event URL
  const qrCodeRef = useRef(null); // Ref for the QR code

  // const downloadQRCode = () => {
  //   const qrCanvas = document.querySelector('canvas'); // Select the QR code canvas element
  
  //   if (qrCanvas) {
  //     const image = qrCanvas.toDataURL('image/png'); // Convert canvas to PNG
  //     const link = document.createElement('a'); // Create an anchor element
  //     link.href = image; // Set the image as the href
  //     link.download = 'qr-code.png'; // Set the download attribute
  //     link.click(); // Programmatically click the link to trigger the download
  //   } else {
  //     console.error('QR code canvas not found!');
  //   }
  // };
  const hiddenCanvasRef = useRef(null); // For the hidden QR code canvas

  const downloadQRCode = () => {
    const qrCanvas = hiddenCanvasRef.current.querySelector('canvas');
    if (qrCanvas) {
      const url = qrCanvas.toDataURL('image/png');
      const eventId = eventUrl.split('eventId=')[1] || 'QR_Code';
      const link = document.createElement('a');
      link.href = url;
      link.download = `${eventId}.png`;
      link.click();
    } else {
      console.error('QR code canvas not found!');
    }
  };
  

  const handleCreateEvent = async (event) => {
    event.preventDefault();
    setIsCreating(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Check if the entered secret is correct
    if (secret !== SECRET_KEY) {
      setErrorMessage("Invalid secret key.");
      setIsCreating(false);
      return;
    }
    // Function to download the QR code as an image

    try {
      // Create JSON payload
      const payload = {
        eventId,
        coverImageUrl,
        customMessage,
        albumOf,
        albumCreatedBy,
      };

      // Handle file upload separately if needed
      if (coverImageFile) {
        // Upload file to a storage service (e.g., Firebase Storage) and get the URL
        // Example placeholder for file upload logic
        // const fileUrl = await uploadFile(coverImageFile);
        // payload.coverImageUrl = fileUrl; // Update payload with file URL
      }

      // Send JSON payload
      const response = await axios.post(API_CREATE_EVENT_URL, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setSuccessMessage("¡El evento se ha creado con éxito!");

        // Generate and set the event URL
        const generatedUrl = `https://snap-moments.com/album?eventId=${eventId}`;
        setEventUrl(generatedUrl);

        // Clear inputs
        setEventId("");
        setCoverImageUrl("");
        setCoverImageFile(null);
        setCustomMessage("");
        setAlbumOf("");
        setAlbumCreatedBy("");
        setSecret(""); // Clear secret input
      } else {
        setErrorMessage("Hubo un error al crear el evento.");
      }
    } catch (error) {
      console.error("Error creating event:", error);
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="create-event-container">
      <Link to="/">
        <Header />
      </Link>
      <h2>Crear Nuevo Evento</h2>
      <form onSubmit={handleCreateEvent}>
        <label>Ingresá el ID del evento:</label>
        <input
          type="text"
          value={eventId}
          onChange={(e) => setEventId(e.target.value)}
          placeholder="ID del evento"
          required
        />
        {/* <label>Mensaje personalizado:</label>
        <input
          type="text"
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          placeholder="Mensaje personalizado"
        /> */}
        <label>Álbum de:</label>
        <input
          type="text"
          value={albumOf}
          onChange={(e) => setAlbumOf(e.target.value)}
          placeholder="Nombre del álbum"
          required
        />
        <label>Álbum creado por:</label> {/* New input field */}
        <input
          type="text"
          value={albumCreatedBy}
          onChange={(e) => setAlbumCreatedBy(e.target.value)}
          placeholder="Nombre del creador del álbum"
          required
        />
        {/* 
        <label>Subir una imagen de portada (opcional):</label>
        <input
          type="file"
          onChange={(e) => setCoverImageFile(e.target.files[0])}
        /> 
        */}
        <label>URL para imagen de portada:</label>
        <input
          type="text"
          value={coverImageUrl}
          onChange={(e) => setCoverImageUrl(e.target.value)}
          placeholder="URL de la imagen de portada"
          required
        />
        <label>Token de autorización:</label>
        <input
          type="text"
          value={secret}
          onChange={(e) => setSecret(e.target.value)}
          placeholder="Secret Key"
          required
        />
        <button type="submit" disabled={isCreating}>
          {isCreating ? "Creando..." : "Crear Evento"}
        </button>
        {isCreating && <p>Creando el evento, por favor espera...</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {/* Display the event URL after creation */}
        {eventUrl && (
          <div className="event-url">
            <p>
              El evento ha sido creado exitosamente. Accedé al álbum en el
              siguiente enlace:
            </p>
            <a href={eventUrl} target="_blank" rel="noopener noreferrer">
              {eventUrl}
            </a>
            {/* Generate and display the QR code for the event URL */}
            <div className="qr-code">
              <h2>QR Code para el evento:</h2>
              <QRCodeCanvas value={eventUrl} size={150} />
              <i
              className="fas fa-download download-qr-icon"
              onClick={downloadQRCode}
            />
            <div style={{ display: 'none' }} ref={hiddenCanvasRef}>
            <QRCodeCanvas value={eventUrl} size={1000} />
          </div>
            </div>
           
          </div>
        )}
      </form>

      <footer className="support-footer">
        <p>
          Si experimentás algún inconveniente durante la creación del evento,
          escribinos a{" "}
          <a href="mailto:contacto@snap-moments.com">
            contacto@snap-moments.com
          </a>
          .
        </p>
      </footer>
    </div>
  );
};

export default CreateEvent;

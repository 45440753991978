import React from "react";
import "./assets/styles/LandingPage.css";
import logo from "./assets/images/logoSnapOk.png";
import exampleImage from "./assets/images/home-page.jpg"; // Replace with actual image path
import { Helmet } from "react-helmet-async";
import FooterInstitucional from "./Components/FooterInstitucional/FooterInstitucional";

function LandingPage() {
  return (
    <div className="LandingPage">
      <Helmet>
        <title>Álbum para Eventos con Código QR</title>
      </Helmet>
      <header className="LandingPage-header">
        <img src={logo} alt="Logo" className="LandingPage-logo" />
      </header>
      <div className="LandingPage-content">
        {/* <h1>Bienvenido</h1> */}
        <h2>Creá recuerdos inolvidables</h2>
        <section className="LandingPage-section">
          <div className="LandingPage-text">
            <p>
              Cada foto captura un recuerdo. Con {""}
              <strong>SnapMoments</strong>, podés conservar esos momentos
              especiales en tu álbum personal, transformándolos en memorias
              duraderas de manera sencilla.
            </p>
            <p>
              Mirá en <strong>tiempo real</strong> cómo tu álbum se llena con
              las fotos de tus invitados, creando un diario visual que captura
              cada instante de tu evento.
            </p>
          </div>
          <div className="LandingPage-image">
            <img src={exampleImage} alt="Representación Visual" />
          </div>
        </section>

        <h2 className="ComoFunciona-section">¿Cómo Funciona?</h2>

        <section className="LandingPage-section expanded-section">
          <ol>
            <li>
              <strong>Escaneá el código QR:</strong> En el evento, usá tu
              teléfono para escanear el código QR. Esto te llevará directamente
              al album de la fiesta.
            </li>
            <li>
              <strong>Subí tus fotos:</strong> Podés subir{" "}
              <u>todas las fotos que quieras</u>. ¡No hay límite para compartir
              tus recuerdos!
            </li>
            <li>
              <strong>Disfrutá en tiempo real:</strong> A medida que se suban
              las fotos, vas a ver cómo se llena el album en tiempo real,
              creando una colección viva de los mejores momentos de tu evento.
            </li>
          </ol>
          {/* <p>
            Valoramos esos momentos mágicos compartidos donde encontramos la verdadera felicidad.
          </p> */}
        </section>

        <h2>Beneficios</h2>

        <section className="LandingPage-section expanded-section">
          <ol>
            <li>
              <strong>Privacidad total:</strong> Solo tus invitados pueden ver
              las fotos.
            </li>
            <li>
              <strong>¡Subí y comentá!</strong> Acompañá tus fotos con un
              mensaje divertido.
            </li>
            <li>
              <strong>Descarga express:</strong> Bajá el álbum completo con un
              clic.
            </li>
            <li>
              <strong>Control parental:</strong> Total tranquilidad con nuestro
              filtro, que evita que se suban fotos inapropiadas.
            </li>
            <li>
              <strong>Proyectá las fotos:</strong> Reproducí la galería en un
              proyector con la función de reproducción automática.
            </li>
          </ol>
        </section>
      </div>
      <FooterInstitucional />
    </div>
  );
}

export default LandingPage;
